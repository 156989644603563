import type * as Types from '../../../typings/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
type SelectCampaignsQueryVariables = Types.Exact<{
  country: Types.Scalars['String']['input'];
}>;


type SelectCampaignsQueryPayload = { __typename?: 'Query', selectCampaigns: { __typename?: 'SelectCampaignPayload', data: Array<{ __typename?: 'SelectCampaign', id: string, name: string, campaignType: Types.GwpCampaignType | null, selectCampaignPageUrl: string | null, gwpCampaignFullLabel: string | null, gwpCampaignShortLabel: string | null } | null> } };


 const SelectCampaignsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"SelectCampaigns"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"country"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"selectCampaigns"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"country"},"value":{"kind":"Variable","name":{"kind":"Name","value":"country"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"campaignType"}},{"kind":"Field","name":{"kind":"Name","value":"selectCampaignPageUrl"}},{"kind":"Field","name":{"kind":"Name","value":"gwpCampaignFullLabel"}},{"kind":"Field","name":{"kind":"Name","value":"gwpCampaignShortLabel"}}]}}]}}]}}]} as unknown as DocumentNode<SelectCampaignsQueryPayload, SelectCampaignsQueryVariables>;


export type SelectCampaignsVariables = SelectCampaignsQueryVariables;
export type SelectCampaignsPayload = SelectCampaignsQueryPayload;

// remove fragments duplicates
// @ts-expect-error
SelectCampaignsDocument['definitions'] = [ ...new Set(SelectCampaignsDocument.definitions) ];

export default SelectCampaignsDocument;